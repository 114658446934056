/**
* Uppy CSS and all of its out-of-the-box plugins
*/
@keyframes fadeIn {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* -------------------------------------------------------------------
  Microtip

  Modern, lightweight css-only tooltips
  Just 1kb minified and gzipped

  @author Ghosh
  @package Microtip

----------------------------------------------------------------------
  1. Base Styles
  2. Direction Modifiers
  3. Position Modifiers
--------------------------------------------------------------------*/
/* ------------------------------------------------
  [1] Base Styles
-------------------------------------------------*/
.uppy-Root [aria-label][role~="tooltip"] {
  position: relative; }

.uppy-Root [aria-label][role~="tooltip"]::before,
.uppy-Root [aria-label][role~="tooltip"]::after {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  transform-origin: top; }

.uppy-Root [aria-label][role~="tooltip"]::before {
  background-size: 100% auto !important;
  content: ""; }

.uppy-Root [aria-label][role~="tooltip"]::after {
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #ffffff;
  content: attr(aria-label);
  font-size: var(--microtip-font-size, 13px);
  font-weight: var(--microtip-font-weight, normal);
  text-transform: var(--microtip-text-transform, none);
  padding: .5em 1em;
  white-space: nowrap;
  box-sizing: content-box; }

.uppy-Root [aria-label][role~="tooltip"]:hover::before,
.uppy-Root [aria-label][role~="tooltip"]:hover::after,
.uppy-Root [aria-label][role~="tooltip"]:focus::before,
.uppy-Root [aria-label][role~="tooltip"]:focus::after {
  opacity: 1;
  pointer-events: auto; }

/* ------------------------------------------------
  [2] Position Modifiers
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-bottom: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  margin-bottom: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover::before {
  transform: translate3d(-50%, -5px, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover::after {
  transform: translate3d(-50%, -5px, 0); }

/* ------------------------------------------------
  [2.1] Top Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]::after {
  transform: translate3d(calc(-100% + 16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover::after {
  transform: translate3d(calc(-100% + 16px), -5px, 0); }

/* ------------------------------------------------
  [2.2] Top Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]::after {
  transform: translate3d(calc(0% + -16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover::after {
  transform: translate3d(calc(0% + -16px), -5px, 0); }

/* ------------------------------------------------
  [2.3] Bottom
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  margin-top: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  transform: translate3d(-50%, -10px, 0);
  bottom: auto;
  left: 50%;
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover::before {
  transform: translate3d(-50%, 0, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  transform: translate3d(-50%, -10px, 0);
  top: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover::after {
  transform: translate3d(-50%, 0, 0); }

/* ------------------------------------------------
  [2.4] Bottom Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]::after {
  transform: translate3d(calc(-100% + 16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover::after {
  transform: translate3d(calc(-100% + 16px), 0, 0); }

/* ------------------------------------------------
  [2.5] Bottom Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]::after {
  transform: translate3d(calc(0% + -16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover::after {
  transform: translate3d(calc(0% + -16px), 0, 0); }

/* ------------------------------------------------
  [2.6] Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translate3d(10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  margin-right: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::after {
  transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [2.7] Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate3d(-10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  margin-left: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::after {
  transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [3] Size
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-size="small"]::after {
  white-space: initial;
  width: 80px; }

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]::after {
  white-space: initial;
  width: 150px; }

.uppy-Root [role~="tooltip"][data-microtip-size="large"]::after {
  white-space: initial;
  width: 260px; }

/**
* General Uppy styles that apply to everything inside the .Uppy container
*/
.uppy-Root {
  all: initial !important;
  box-sizing: border-box !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  line-height: 1 !important;
  -webkit-font-smoothing: antialiased !important; }

.uppy-Root *, .uppy-Root *:before, .uppy-Root *:after {
  box-sizing: inherit !important; }

.UppyIcon {
  max-width: 100% !important;
  max-height: 100% !important;
  fill: currentColor !important;
  display: inline-block !important;
  vertical-align: text-top !important;
  overflow: hidden !important; }

.UppyIcon--svg-baseline {
  bottom: -0.125em !important;
  position: relative !important; }

.UppyButton--circular {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  transition: all 0.3s !important; }

.UppyButton--blue {
  color: #fff !important;
  background-color: #2275D7 !important; }
  .UppyButton--blue:hover, .UppyButton--blue:focus {
    background-color: #1b5dab !important; }

.UppyButton--red {
  color: #fff !important;
  background-color: #D32F2F !important; }
  .UppyButton--red:hover, .UppyButton--red:focus {
    background-color: #ab2424 !important; }

.UppyButton--sizeM {
  width: 60px !important;
  height: 60px !important; }

.UppyButton--sizeS {
  width: 45px !important;
  height: 45px !important; }

.uppy-u-reset {
  animation: none 0s ease 0s 1 normal none running;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  border: medium none currentColor !important;
  border-collapse: separate !important;
  border-image: none !important;
  border-radius: 0 !important;
  border-spacing: 0 !important;
  bottom: auto !important;
  box-shadow: none !important;
  caption-side: top !important;
  clear: none !important;
  clip: auto !important;
  color: #000 !important;
  columns: auto !important;
  column-count: auto !important;
  column-fill: balance !important;
  column-gap: normal !important;
  column-rule: medium none currentColor !important;
  column-span: 1 !important;
  column-width: auto !important;
  content: normal !important;
  counter-increment: none !important;
  counter-reset: none !important;
  cursor: auto !important;
  display: inline !important;
  empty-cells: show !important;
  float: none !important;
  font-family: serif !important;
  font-size: medium !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  height: auto !important;
  -webkit-hyphens: none !important;
      -ms-hyphens: none !important;
          hyphens: none !important;
  left: auto !important;
  letter-spacing: normal !important;
  list-style: disc outside none !important;
  margin: 0 !important;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  opacity: 1 !important;
  orphans: 2 !important;
  outline: medium none invert !important;
  overflow: visible !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
  padding: 0 !important;
  page-break-after: auto !important;
  page-break-before: auto !important;
  page-break-inside: auto !important;
  perspective: none !important;
  perspective-origin: 50% 50% !important;
  position: static !important;
  right: auto !important;
  -moz-tab-size: 8 !important;
       tab-size: 8 !important;
  table-layout: auto !important;
  text-align: left !important;
  text-align-last: auto !important;
  text-decoration: none !important;
  text-indent: 0 !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: auto !important;
  transform: none !important;
  transform-origin: 50% 50% 0 !important;
  transform-style: flat !important;
  transition: none 0s ease 0s !important;
  unicode-bidi: normal !important;
  vertical-align: baseline !important;
  visibility: visible !important;
  white-space: normal !important;
  widows: 2 !important;
  width: auto !important;
  word-spacing: normal !important;
  z-index: auto !important; }

.uppy-c-textInput {
  border: 1px solid rgba(147, 147, 147, 0.5) !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
  padding: 6px 8px !important; }

.uppy-Dashboard--wide .uppy-c-textInput {
  font-size: 15px !important;
  line-height: 1.8 !important;
  padding: 8px 12px !important; }

.uppy-c-textInput:focus {
  border-color: #2275D7 !important;
  outline: none !important;
  box-shadow: 0 0 1px 1px rgba(34, 117, 215, 0.5) !important; }

.uppy-c-btn {
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  font-family: inherit !important;
  font-size: 16px !important;
  line-height: 1 !important;
  font-weight: 500 !important;
  transition: all 0.3s !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer !important; }

.uppy-c-btn-primary {
  font-size: 13px !important;
  padding: 10px 18px !important;
  border-radius: 4px !important;
  background-color: #2275D7 !important;
  color: #fff !important; }

.uppy-Dashboard--wide .uppy-c-btn-primary {
  font-size: 15px !important;
  padding: 13px 28px !important; }

.uppy-c-btn-primary:hover {
  background-color: #1b5dab !important; }

.uppy-c-btn-primary:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5) !important; }

.uppy-c-btn-link {
  font-size: 13px !important;
  line-height: 1 !important;
  padding: 10px 18px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #000 !important; }

.uppy-Dashboard--wide .uppy-c-btn-link {
  font-size: 15px !important;
  padding: 13px 28px !important; }

.uppy-c-btn-link:hover {
  text-decoration: underline !important; }

.uppy-c-btn-link:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem rgba(34, 117, 215, 0.5) !important; }

.uppy-c-btn--small {
  font-size: 0.9em !important;
  padding: 7px 16px !important;
  border-radius: 2px !important; }

.uppy-Dashboard--wide .uppy-c-btn--small {
  padding: 8px 10px !important;
  border-radius: 2px !important; }

.uppy-FileInput-container {
  margin-bottom: 15px !important; }

.uppy-FileInput-btn {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  font-family: sans-serif !important;
  font-size: 0.85em !important;
  padding: 10px 15px !important;
  color: #14457f !important;
  border: 1px solid #14457f !important;
  border-radius: 8px !important;
  cursor: pointer !important; }
  .uppy-FileInput-btn:hover {
    background-color: #14457f !important;
    color: #fff !important; }

.uppy-Informer {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 0 15px !important;
  height: 35px !important;
  line-height: 35px !important;
  background-color: #000;
  color: #fff !important;
  opacity: 1 !important;
  transform: none !important;
  transition: all 300ms ease-in !important;
  z-index: 1004 !important; }
  .uppy-Dashboard--wide .uppy-Informer {
    height: 45px !important;
    line-height: 45px !important;
    font-size: 13px !important; }

.uppy-Informer[aria-hidden=true] {
  opacity: 0 !important;
  transform: translateY(200%) !important;
  transition: all 300ms ease-in !important; }

.uppy-Informer p {
  margin: 0 !important;
  padding: 0 !important;
  height: 35px !important;
  line-height: 35px !important; }
  .uppy-Dashboard--wide .uppy-Informer p {
    height: 45px !important;
    line-height: 45px !important; }

.uppy-Informer span {
  line-height: 12px !important;
  width: 13px !important;
  height: 13px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  position: relative !important;
  top: -1px !important;
  left: 3px !important;
  font-size: 10px !important;
  margin-left: -1px !important; }

.uppy-Informer span:hover {
  cursor: help !important; }

.uppy-Informer span:after {
  line-height: 1.3 !important;
  word-wrap: break-word !important; }

.uppy-Dashboard--modal {
  z-index: 1001 !important; }

.uppy-Dashboard--modal[aria-hidden=true] {
  display: none !important; }

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0; }
  to {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    transform: translate3d(0, -20%, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1; }
  to {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0; } }

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    transform: translate3d(0, -20%, 0);
    opacity: 0; } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 768px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
      animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 768px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
      animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard-isFixed {
  overflow: hidden !important;
  height: 100vh !important; }

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1001 !important; }

.uppy-Dashboard-inner {
  position: relative !important;
  background-color: #fafafa !important;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  min-width: 300px !important;
  min-height: 400px !important;
  outline: none !important;
  border: 1px solid rgba(147, 147, 147, 0.2) !important; }
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1002 !important; }
  @media only screen and (min-width: 768px) {
    .uppy-Dashboard-inner {
      width: 750px;
      height: 550px;
      border-radius: 5px !important; } }

.uppy-Dashboard-innerWrap {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  height: 100% !important;
  overflow: hidden !important;
  min-height: 300px !important;
  position: relative !important; }
  @media only screen and (min-width: 768px) {
    .uppy-Dashboard-innerWrap {
      border-radius: 5px !important; } }

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  border: none !important; }
  @media only screen and (min-width: 768px) {
    .uppy-Dashboard--modal .uppy-Dashboard-inner {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15) !important; } }

.uppy-Dashboard-close {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  display: none !important;
  position: absolute !important;
  top: 2px !important;
  right: 8px !important;
  cursor: pointer !important;
  color: rgba(82, 82, 82, 0.5) !important;
  transition: all 0.3s !important;
  font-size: 23px !important; }
  .uppy-Dashboard--wide .uppy-Dashboard-close {
    font-size: 30px !important;
    top: 2px !important;
    right: 8px !important; }
  .uppy-Dashboard--modal .uppy-Dashboard-close {
    z-index: 1005 !important;
    display: block !important; }

.uppy-Dashboard-close:hover {
  color: #2275D7 !important; }

.uppy-DashboardTabs {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-bottom: 1px solid rgba(147, 147, 147, 0.3) !important; }

.uppy-DashboardTabs[aria-hidden=true] {
  display: none !important; }

.uppy-DashboardTabs-title {
  font-size: 15px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  color: #525252 !important; }
  .uppy-Dashboard--wide .uppy-DashboardTabs-title {
    font-size: 17px !important;
    line-height: 40px !important; }

.uppy-Dashboard-browse {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  cursor: pointer !important;
  color: rgba(34, 117, 215, 0.9) !important; }

.uppy-Dashboard-browse:focus {
  outline: none !important;
  border-bottom: 2px solid #2275D7 !important; }

.uppy-DashboardTabs-list {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-align: center !important;
      align-items: center !important; }

.uppy-DashboardTab {
  width: 70px !important;
  margin: 0 !important;
  display: inline-block !important;
  text-align: center !important; }
  .uppy-Dashboard--wide .uppy-DashboardTab {
    width: 75px !important;
    margin: 0 5px !important; }

.uppy-DashboardTab-btn {
  width: 100% !important;
  cursor: pointer !important;
  border: 0 !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
       appearance: none !important;
  transition: all 0.3s !important;
  color: #535353 !important; }

.uppy-DashboardTab-btn:hover {
  color: #2275D7 !important; }

.uppy-DashboardTab-name {
  font-size: 8px !important;
  line-height: 11px !important;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important; }
  .uppy-Dashboard--wide .uppy-DashboardTab-name {
    font-size: 9px !important; }

.uppy-DashboardTab .UppyIcon {
  width: 18px !important;
  height: 18px !important;
  vertical-align: middle !important; }
  .uppy-Dashboard--wide .uppy-DashboardTab .UppyIcon {
    width: 23px !important;
    height: 23px !important; }

.uppy-Dashboard-input {
  width: 0.1px !important;
  height: 0.1px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  z-index: -1 !important; }

.uppy-DashboardContent-bar {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  height: 40px !important;
  width: 100% !important;
  border-bottom: 1px solid rgba(147, 147, 147, 0.3) !important;
  z-index: 1004 !important;
  background-color: whitesmoke !important;
  padding: 0 15px !important; }
  .uppy-Dashboard--wide .uppy-DashboardContent-bar {
    height: 50px !important; }

.uppy-DashboardContent-title {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  font-size: 14px !important;
  line-height: 40px !important;
  font-weight: normal !important;
  max-width: 170px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow-x: hidden !important;
  margin: auto !important; }
  .uppy-Dashboard--wide .uppy-DashboardContent-title {
    font-size: 16px !important;
    line-height: 50px !important;
    max-width: 300px !important; }

.uppy-DashboardContent-back {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  color: #2275D7 !important; }
  .uppy-Dashboard--wide .uppy-DashboardContent-back {
    font-size: 15px !important; }

.uppy-DashboardContent-panel {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: translate3d(0, -105%, 0) !important;
  transition: transform 0.2s ease-in-out !important;
  background-color: whitesmoke !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15) !important;
  padding-top: 40px !important;
  overflow: hidden !important;
  z-index: 1004 !important; }
  .uppy-Dashboard--wide .uppy-DashboardContent-panel {
    padding-top: 50px !important; }

.uppy-DashboardContent-panel[aria-hidden=false] {
  transform: translate3d(0, 0, 0) !important; }

.uppy-Dashboard-progress {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 12% !important; }

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

.uppy-Dashboard-next {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: none !important;
  background-color: rgba(147, 147, 147, 0.7) !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
       appearance: none !important;
  border: 0 !important;
  z-index: 1002 !important;
  transition: background-color 0.5s !important;
  color: #fff !important;
  font-family: inherit !important;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  cursor: not-allowed !important; }

.uppy-Dashboard-next.is-active {
  cursor: pointer !important;
  background-color: #2275D7 !important; }
  .uppy-Dashboard-next.is-active:hover {
    background-color: #14457f !important; }

.uppy-Dashboard-filesContainer {
  position: relative !important;
  overflow-y: hidden !important;
  margin: 0 !important;
  -ms-flex: 1 !important;
      flex: 1 !important; }
  .uppy-Dashboard-filesContainer:after {
    content: '' !important;
    display: table !important;
    clear: both !important; }

.uppy-Dashboard-files {
  margin: 0 !important;
  padding: 0 0 10px 0 !important;
  overflow-y: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important; }

.uppy-Dashboard--wide .uppy-Dashboard-files {
  padding: 15px 10px 10px 10px !important; }

.uppy-Dashboard.drag .uppy-Dashboard-innerWrap {
  background-color: #cccccc !important; }

.uppy-Dashboard.drag .uppy-Dashboard-files--noFiles {
  border-color: #cccccc !important; }

.uppy-Dashboard-bgIcon {
  height: 100% !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.uppy-Dashboard.drag .uppy-Dashboard-bgIcon {
  opacity: 1 !important; }

.uppy-Dashboard-dropFilesTitle {
  max-width: 460px !important;
  text-align: center !important;
  font-size: 18px !important;
  line-height: 1.45 !important;
  font-weight: 400 !important;
  color: rgba(82, 82, 82, 0.8) !important;
  padding: 0 15px !important; }
  .uppy-Dashboard--wide .uppy-Dashboard-dropFilesTitle {
    font-size: 24px !important; }

.uppy-Dashboard-note {
  font-size: 13px !important;
  line-height: 1.2 !important;
  text-align: center !important;
  color: rgba(82, 82, 82, 0.8) !important;
  position: absolute !important;
  bottom: 45px !important;
  left: 0 !important;
  width: 100% !important; }
  .uppy-Dashboard--wide .uppy-Dashboard-note {
    font-size: 16px !important; }

.uppy-Dashboard-poweredBy {
  width: 100% !important;
  text-align: center !important;
  position: absolute !important;
  bottom: 23px !important;
  font-size: 11px !important;
  color: #939393 !important;
  text-decoration: none !important;
  padding-top: 8px !important;
  padding-right: 2px !important; }

.uppy-Dashboard-poweredByUppy {
  color: #939393 !important; }

.uppy-Dashboard-poweredByIcon {
  stroke: #939393 !important;
  fill: none !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  position: relative !important;
  top: 1px !important;
  opacity: 0.9 !important; }

.uppy-DashboardItem {
  list-style: none !important;
  margin: 10px 0 !important;
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  border-bottom: 1px solid #ececec !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
    float: left !important;
    width: 140px !important;
    height: 170px !important;
    margin: 5px 15px !important;
    border: 0 !important;
    background-color: initial !important;
    border-bottom: none !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important; }

.uppy-DashboardItem-preview {
  width: 60px !important;
  height: 60px !important;
  border-bottom: 0 !important;
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-align: center !important;
      align-items: center !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-preview {
    width: 100% !important;
    height: 100px !important;
    border: 0 !important; }

.uppy-DashboardItem-previewLink {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1002 !important; }

.uppy-DashboardItem-sourceIcon {
  display: inline-block !important;
  vertical-align: middle !important;
  width: 10px !important;
  height: 10px !important;
  color: rgba(147, 147, 147, 0.6) !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-sourceIcon {
    width: 10px !important;
    height: 10px !important; }

.uppy-DashboardItem-previewInnerWrap {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  box-shadow: 0 0 2px 0 rgba(147, 147, 147, 0.7) !important;
  border-radius: 3px !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-previewInnerWrap {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important;
    border-radius: 3px !important; }

.uppy-DashboardItem-previewInnerWrap:after {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background-color: rgba(0, 0, 0, 0.65) !important;
  display: none !important;
  z-index: 1001 !important; }

.uppy-DashboardItem-preview img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important; }

.uppy-DashboardItem-previewIconWrap {
  height: 80px !important;
  max-height: 90% !important;
  position: relative !important; }

.uppy-DashboardItem-previewIconBg {
  width: 100% !important;
  height: 100% !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 1px) !important; }

.uppy-DashboardItem-previewIcon {
  width: 18px !important;
  height: 18px !important;
  z-index: 100 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-previewIcon {
    width: 25px !important;
    height: 25px !important; }

.uppy-DashboardItem-previewType {
  position: absolute !important;
  bottom: 14px !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  text-transform: uppercase !important;
  font-size: 9px !important;
  letter-spacing: 1px !important;
  color: #525252 !important;
  z-index: 100 !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.uppy-DashboardItem-info {
  padding-left: 15px !important;
  position: relative !important;
  max-width: 65% !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-info {
    width: 100% !important;
    max-width: 100% !important;
    -ms-flex: 1 !important;
        flex: 1 !important;
    padding: 10px 19px 0 3px !important;
    border-top: 0 !important; }

.uppy-DashboardItem-name {
  font-size: 11px !important;
  line-height: 1.35 !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 28px !important;
  margin-bottom: 3px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-name {
    word-break: break-all !important;
    white-space: normal !important; }

.uppy-DashboardItem-name a {
  text-decoration: underline !important;
  color: #000 !important; }

.uppy-DashboardItem-status {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #939393 !important;
  margin-bottom: 4px !important; }

.uppy-DashboardItem-statusSize {
  display: inline-block !important;
  vertical-align: bottom !important;
  text-transform: uppercase !important;
  margin-right: 3px !important; }

.uppy-DashboardItem-edit,
.uppy-DashboardItem-copyLink {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  font-size: 12px !important;
  text-align: left !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 0 !important;
  right: -20px !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-edit, .uppy-Dashboard--wide
  .uppy-DashboardItem-copyLink {
    top: 9px !important;
    right: 3px !important; }

.uppy-DashboardItem-edit .UppyIcon {
  width: 11px !important;
  height: 11px !important;
  color: #525252 !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-edit .UppyIcon {
    width: 12px !important;
    height: 12px !important; }

.uppy-DashboardItem-copyLink .UppyIcon {
  width: 11px !important;
  height: 11px !important;
  color: #525252 !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-copyLink .UppyIcon {
    width: 13px !important;
    height: 13px !important; }

.uppy-DashboardItem-action {
  position: absolute !important;
  top: 23px !important;
  right: 5px !important;
  z-index: 1002 !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-action {
    top: -8px !important;
    right: -8px !important; }

.uppy-DashboardItem-remove {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  cursor: pointer !important;
  color: #858585 !important;
  width: 16px !important;
  height: 16px !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-remove {
    width: 20px !important;
    height: 20px !important;
    color: #666666 !important; }

.uppy-DashboardItem.is-inprogress:not(.is-resumable) .uppy-DashboardItem-remove {
  display: none !important; }

.uppy-DashboardItem-progress {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1002 !important;
  color: #fff !important;
  text-align: center !important;
  width: 120px !important;
  display: none !important;
  transition: all .35 ease !important; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress {
  transform: initial !important;
  top: -9px !important;
  right: -8px !important;
  left: initial !important;
  width: auto !important; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-error .uppy-DashboardItem-progress {
  display: block !important; }

.uppy-DashboardItem-progressIndicator {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  display: inline-block !important;
  width: 38px !important;
  height: 38px !important;
  opacity: 0.9 !important;
  transition: all .35s ease !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-progressIndicator {
    width: 55px !important;
    height: 55px !important; }

button.uppy-DashboardItem-progressIndicator {
  cursor: pointer !important; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
  width: 18px !important;
  height: 18px !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
    width: 28px !important;
    height: 28px !important; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  width: 18px !important;
  height: 18px !important;
  opacity: 1 !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    width: 25px !important;
    height: 25px !important; }

.uppy-DashboardItem-progressInfo {
  font-size: 9px !important;
  line-height: 1 !important;
  font-weight: 500 !important;
  height: 10px !important;
  display: none !important;
  position: absolute !important;
  bottom: -10px !important;
  left: 0 !important;
  width: 100% !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3) !important; }
  .uppy-Dashboard--wide .uppy-DashboardItem-progressInfo {
    display: block !important; }

.UppyIcon-progressCircle {
  width: 100% !important;
  height: 100% !important; }

.uppy-DashboardItem .bg {
  stroke: rgba(255, 255, 255, 0.4) !important;
  opacity: 0 !important; }

.uppy-DashboardItem .progress {
  stroke: #fff !important;
  transition: stroke-dashoffset .5s ease-out !important;
  opacity: 0 !important; }

.uppy-DashboardItem .play {
  stroke: #fff !important;
  fill: #fff !important;
  opacity: 0 !important;
  transition: all 0.2s !important;
  display: none !important; }

.uppy-DashboardItem .cancel {
  fill: #fff !important;
  opacity: 0 !important;
  transition: all 0.2s !important; }

.uppy-DashboardItem .pause {
  stroke: #fff !important;
  fill: #fff !important;
  opacity: 0 !important;
  transition: all 0.2s !important;
  display: none !important; }

.uppy-DashboardItem.is-error .retry {
  fill: #fff !important; }

.uppy-DashboardItem.is-resumable .pause, .uppy-DashboardItem.is-resumable .play {
  display: block !important; }

.uppy-DashboardItem.is-resumable .cancel {
  display: none !important; }

.UppyIcon-progressCircle .check {
  opacity: 0 !important;
  fill: #fff !important;
  transition: all 0.2s !important; }

.uppy-DashboardItem.is-inprogress .bg, .uppy-DashboardItem.is-inprogress .progress, .uppy-DashboardItem.is-inprogress .pause, .uppy-DashboardItem.is-inprogress .cancel {
  opacity: 1 !important; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-previewInnerWrap:after {
  display: block !important; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-previewInnerWrap:after {
  display: block !important; }

.uppy-DashboardItem.is-paused .pause {
  opacity: 0 !important; }

.uppy-DashboardItem.is-paused .play {
  opacity: 1 !important; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  cursor: default !important; }

.uppy-DashboardItem.is-complete .progress {
  stroke: #1BB240 !important;
  fill: #1BB240 !important;
  opacity: 1 !important; }

.uppy-DashboardItem.is-complete .check {
  opacity: 1 !important; }

.uppy-DashboardItem-progressNum {
  position: relative !important;
  z-index: 1001 !important; }

.uppy-DashboardItem-progressInner {
  height: 15px !important;
  background-color: #2275D7 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important; }

.uppy-Dashboard-actions {
  height: 55px !important;
  border-top: 1px solid rgba(147, 147, 147, 0.2) !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  padding: 0 15px !important; }

.uppy-Dashboard--wide .uppy-Dashboard-actions {
  height: 65px !important; }

.uppy-Dashboard-actionsBtn {
  margin-right: 10px !important; }

.uppy-Dashboard-pauseResume .UppyIcon {
  width: 100% !important;
  height: 100% !important; }

.uppy-Dashboard-upload {
  position: relative !important;
  width: 50px !important;
  height: 50px !important; }
  .uppy-Dashboard--wide .uppy-Dashboard-upload {
    width: 60px !important;
    height: 60px !important; }

.uppy-Dashboard-upload .UppyIcon {
  position: relative !important;
  top: 1px !important;
  width: 50% !important; }

.uppy-Dashboard-uploadCount {
  position: absolute !important;
  top: -12px !important;
  right: -12px !important;
  background-color: #1BB240 !important;
  color: #fff !important;
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  font-size: 8px !important; }
  .uppy-Dashboard--wide .uppy-Dashboard-uploadCount {
    width: 18px !important;
    height: 18px !important;
    line-height: 18px !important;
    font-size: 9px !important; }

.uppy-DashboardFileCard {
  transform: translate3d(0, 0, 0) !important;
  transition: transform 0.2s ease-in-out !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1004 !important;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important; }

.uppy-DashboardFileCard[aria-hidden=true] {
  transform: translate3d(0, -105%, 0) !important; }

.uppy-DashboardFileCard-inner {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  height: 100% !important;
  padding-top: 40px !important; }
  .uppy-Dashboard--wide .uppy-DashboardFileCard-inner {
    padding-top: 50px !important; }

.uppy-DashboardFileCard-preview {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
  border-bottom: 1px solid rgba(147, 147, 147, 0.3) !important;
  background-color: #f9f9f9;
  position: relative !important; }

.uppy-DashboardFileCard-preview img {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15) !important;
  max-width: 90% !important;
  max-height: 90% !important;
  object-fit: cover !important;
  border-radius: 3px !important;
  position: absolute !important; }

.uppy-DashboardFileCard-info {
  padding: 30px 20px 20px 20px !important;
  max-height: 40% !important;
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
  overflow-y: auto !important; }

.uppy-DashboardFileCard-fieldset {
  font-size: 0 !important;
  border: 0 !important;
  padding: 0 !important;
  max-width: 640px !important;
  margin: auto !important;
  margin-bottom: 12px !important; }

.uppy-DashboardFileCard-label {
  display: inline-block !important;
  vertical-align: middle !important;
  width: 22% !important;
  font-size: 12px !important;
  color: #525252 !important; }
  .uppy-Dashboard--wide .uppy-DashboardFileCard-label {
    font-size: 13px !important; }

.uppy-DashboardFileCard-input {
  display: inline-block !important;
  vertical-align: middle !important;
  width: 78% !important; }

.uppy-DragDrop-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  border-radius: 7px !important;
  background-color: #fff !important; }

.uppy-DragDrop-inner {
  margin: 0 !important;
  text-align: center !important;
  padding: 80px 20px !important;
  line-height: 1.4 !important; }

.uppy-DragDrop-arrow {
  width: 60px !important;
  height: 60px !important;
  fill: #e0e0e0 !important;
  margin-bottom: 17px !important; }

.uppy-DragDrop--is-dragdrop-supported {
  border: 2px dashed !important;
  border-color: #adadad !important; }

.uppy-DragDrop-container.drag {
  border-color: #939393 !important;
  background-color: #e6e6e6 !important; }

.uppy-DragDrop-container.drag .uppy-DragDrop-arrow {
  fill: #939393 !important; }

.uppy-DragDrop-label {
  display: block !important;
  cursor: pointer !important;
  font-size: 1.15em !important;
  margin-bottom: 5px !important; }

.uppy-DragDrop-note {
  font-size: 1em !important;
  color: #adadad !important; }

.uppy-DragDrop-dragText {
  color: #2275D7 !important; }

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-flow: column wrap !important;
      flex-flow: column wrap !important;
  height: 100% !important; }

.uppy-Provider-authIcon .UppyIcon {
  width: 100px !important;
  height: 75px !important;
  color: rgba(82, 82, 82, 0.3) !important;
  margin-bottom: 15px !important; }

.uppy-Provider-authTitle {
  font-size: 20px !important;
  line-height: 1.4 !important;
  font-weight: 400 !important;
  margin-bottom: 30px !important;
  padding: 0 15px !important;
  max-width: 500px !important;
  text-align: center !important; }

.uppy-Provider-breadcrumbs {
  -ms-flex: 1 !important;
      flex: 1 !important;
  color: #535353 !important;
  font-size: 12px !important;
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important; }

.uppy-Provider-breadcrumbsIcon {
  display: inline !important;
  color: #535353 !important;
  vertical-align: middle !important;
  margin-right: 8px !important; }

.uppy-Provider-breadcrumbsIcon .UppyIcon {
  width: 13px !important;
  height: 13px !important; }

.uppy-Provider-breadcrumbs button {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  cursor: pointer !important;
  font-size: 14px !important; }

.uppy-Provider-breadcrumbs button:hover {
  text-decoration: underline !important; }

.uppy-Provider-breadcrumbs button:focus {
  outline: 1px dotted #919191 !important; }

.uppy-Provider-breadcrumbs li {
  display: inline-block !important;
  margin: 0 !important; }

.uppy-Provider-breadcrumbs li ~ li:before {
  content: '/' !important;
  padding: 0 7px !important; }

.uppy-ProviderBrowser {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  height: 100% !important; }

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0 !important; }

.uppy-ProviderBrowser-user:after {
  content: '\00B7' !important;
  position: relative !important;
  left: 4px !important; }

.uppy-ProviderBrowser-header {
  z-index: 1001 !important;
  border-bottom: 1px solid #ebebeb !important;
  position: relative !important; }

.uppy-ProviderBrowser-headerBar {
  height: 40px !important;
  line-height: 40px !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  padding: 0 16px !important;
  background-color: #f9f9f9 !important;
  z-index: 1001 !important;
  color: #606060 !important; }

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center !important;
  display: block !important; }

.uppy-ProviderBrowser-search {
  width: 100% !important;
  background-color: #fff !important;
  position: relative !important;
  height: 30px !important;
  margin-top: 15px !important;
  margin-bottom: 5px !important; }

.uppy-ProviderBrowser-searchInput {
  width: 100% !important;
  height: 30px !important;
  background-color: transparent !important;
  outline: 0 !important;
  font-family: sans-serif !important;
  font-size: 14px !important;
  line-height: 30px !important;
  border: 0 !important;
  padding: 0 16px 0 43px !important;
  z-index: 1001 !important; }

.uppy-ProviderBrowser-searchInput::-webkit-input-placeholder,
.uppy-ProviderBrowser-searchInput::-moz-placeholder,
.uppy-ProviderBrowser-searchInput::-ms-input-placeholder {
  color: rgba(147, 147, 147, 0.75) !important;
  letter-spacing: 1px !important; }

.uppy-ProviderBrowser-searchIcon {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  left: 16px !important;
  top: 7px !important;
  z-index: 1002 !important;
  color: rgba(147, 147, 147, 0.6) !important; }

.uppy-ProviderBrowser-searchClose {
  width: 12px !important;
  height: 12px !important;
  position: absolute !important;
  right: 16px !important;
  top: 7px !important;
  z-index: 1002 !important;
  color: rgba(147, 147, 147, 0.6) !important;
  cursor: pointer !important; }

.uppy-ProviderBrowser-userLogout {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  cursor: pointer !important; }
  .uppy-ProviderBrowser-userLogout:hover {
    text-decoration: underline !important; }

.uppy-ProviderBrowser-body {
  -ms-flex: 1 !important;
      flex: 1 !important;
  position: relative !important; }

.uppy-ProviderBrowser-list {
  -ms-flex: 1 !important;
      flex: 1 !important;
  position: relative !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #fff !important;
  border-spacing: 0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important; }

.uppy-ProviderBrowserItem-inner {
  background: none !important;
  -webkit-appearance: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: inherit !important;
  cursor: pointer !important;
  font-weight: 600 !important; }

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff !important; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem {
    padding: 10px 15px !important; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
    max-width: 80% !important;
    word-wrap: break-word !important;
    text-align: left !important;
    line-height: 1.4 !important; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img {
    vertical-align: text-top !important;
    margin-right: 3px !important; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox label:before {
    border-color: rgba(82, 82, 82, 0.4) !important; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox input:checked + label:before {
    border-color: #2275D7 !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowser-list {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: row !important;
      flex-direction: row !important;
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
  -ms-flex-align: start !important;
      align-items: flex-start !important;
  padding: 6px !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowser-list:after {
  content: '' !important;
  -ms-flex: auto !important;
      flex: auto !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem {
  display: inline-block !important;
  width: 50% !important;
  position: relative !important;
  padding: 8px !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-Dashboard--wide .uppy-ProviderBrowserItem {
  width: 33.3333% !important;
  padding: 12px !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem--selected .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px rgba(27, 93, 171, 0.9) !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner {
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  border: 2px solid transparent !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem img {
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle !important; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox {
  display: none !important; }

.uppy-Dashboard--wide .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem {
  width: 25% !important; }

.uppy-ProviderBrowserItem-checkbox input {
  opacity: 0 !important; }

.uppy-ProviderBrowserItem-checkbox {
  position: relative !important;
  display: inline-block !important;
  top: -3px !important;
  margin-right: 20px !important; }

.uppy-ProviderBrowserItem-checkbox label {
  display: block !important; }

.uppy-ProviderBrowserItem-checkbox label::before,
.uppy-ProviderBrowserItem-checkbox label::after {
  position: absolute !important;
  cursor: pointer !important; }

.uppy-ProviderBrowserItem-checkbox label:before {
  content: "" !important;
  display: inline-block !important;
  height: 18px !important;
  width: 18px !important;
  top: 2px !important;
  border: 1px solid #2275D7 !important;
  background-color: #fff !important;
  border-radius: 2px !important; }

.uppy-ProviderBrowserItem-checkbox label:after {
  content: '' !important;
  display: inline-block !important;
  height: 5px !important;
  width: 8px !important;
  left: 5px !important;
  top: 8px !important;
  border-left: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  transform: rotate(-45deg) !important; }

.uppy-ProviderBrowserItem-checkbox input + label::after {
  content: none !important; }

.uppy-ProviderBrowserItem-checkbox input:checked + label::after {
  content: '' !important; }

.uppy-ProviderBrowserItem-checkbox input:checked + label::before {
  background-color: #2275D7 !important; }

.uppy-ProviderBrowserItem-checkbox input:focus + label::before {
  outline: #3b99fc auto 5px !important; }

.uppy-ProviderBrowser-footer {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  background: #fff !important;
  height: 65px !important;
  border-top: 1px solid rgba(147, 147, 147, 0.3) !important;
  padding: 0 15px !important; }
  .uppy-ProviderBrowser-footer button {
    margin-right: 10px !important; }

.uppy-Url {
  width: 100% !important;
  height: 100% !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-align: center !important;
      align-items: center !important; }

.uppy-Url-input {
  width: 90% !important;
  max-width: 650px !important;
  margin-bottom: 15px !important; }

.uppy-Dashboard--wide .uppy-Url-input {
  margin-bottom: 30px !important; }

.uppy-Url-importButton {
  padding: 13px 25px !important; }

.uppy-Dashboard--wide .uppy-Url-importButton {
  padding: 13px 35px !important; }

.uppy-ProgressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 10000;
  transition: height .2s; }

.uppy-ProgressBar[aria-hidden=true] {
  height: 0; }

.uppy-ProgressBar-inner {
  background-color: #2275D7;
  box-shadow: 0 0 10px rgba(34, 117, 215, 0.7);
  height: 100%;
  width: 0;
  transition: width 0.4s ease; }

.uppy-ProgressBar-percentage {
  display: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; }

.uppy-StatusBar {
  display: -ms-flexbox !important;
  display: flex !important;
  position: relative !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #1a1a1a !important;
  z-index: 1001 !important;
  transition: height .2s !important; }

.uppy-Dashboard--wide .uppy-StatusBar {
  height: 45px !important;
  font-size: 14px !important; }

.uppy-StatusBar[aria-hidden=true] {
  overflow-y: hidden !important;
  height: 0 !important; }

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1BB240 !important; }

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #D32F2F !important; }

.uppy-StatusBar.is-complete .uppy-StatusBar-content {
  width: 100% !important;
  text-align: center !important;
  padding-left: 0 !important;
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #fff !important;
  height: 65px !important;
  border-top: 1px solid rgba(147, 147, 147, 0.3) !important; }

.uppy-StatusBar-progress {
  background-color: #2275D7 !important;
  height: 100% !important;
  position: absolute !important;
  z-index: 1001 !important;
  transition: background-color, width .3s ease-out !important; }
  .uppy-StatusBar-progress.is-indeterminate {
    background-size: 64px 64px !important;
    background-image: linear-gradient(45deg, #1b5dab 25%, transparent 25%, transparent 50%, #1b5dab 50%, #1b5dab 75%, transparent 75%, transparent) !important;
    animation: statusBarProgressStripes 1s linear infinite; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none !important; }

@keyframes statusBarProgressStripes {
  from {
    background-position: 64px 0; }
  to {
    background-position: 0 0; } }

.uppy-StatusBar-content {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  position: relative !important;
  z-index: 1002 !important;
  padding-left: 15px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  color: #fff !important;
  height: 100% !important; }

.uppy-StatusBar-contentPadding {
  margin-right: 0.5ch !important; }

.uppy-StatusBar-status {
  line-height: 1.35 !important;
  font-weight: normal !important;
  letter-spacing: 0.5px !important; }

.uppy-StatusBar-statusPrimary {
  font-size: 13px !important; }

.uppy-StatusBar-statusSecondary {
  font-size: 11px !important;
  display: none !important; }

.uppy-StatusBar--detailedProgress .uppy-StatusBar-statusSecondary {
  display: inline !important; }

.uppy-StatusBar-statusIndicator {
  color: #fff !important;
  margin-right: 15px !important;
  cursor: pointer !important; }

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  width: 15px !important;
  margin-right: 7px !important; }

.uppy-StatusBar-actions {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 15px !important;
  z-index: 1004 !important; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100% !important;
  position: static !important;
  padding: 0 15px !important; }

.uppy-StatusBar-actionBtn {
  font-size: 12px !important;
  padding: 6px !important;
  border-radius: 4px !important; }

.uppy-Dashboard--wide .uppy-StatusBar-actionBtn {
  padding: 7px 10px !important; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  font-size: 14px !important;
  width: 100% !important;
  padding: 15px 10px !important; }

.uppy-Dashboard--wide .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  padding: 13px 28px !important;
  width: auto !important; }

.uppy-StatusBar-actionBtn:not(:last-child) {
  margin-right: 10px !important; }

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important; }

.uppy-StatusBar-actionBtn--retry {
  background-color: #fff !important;
  color: #D32F2F !important;
  border: 1px solid transparent !important; }

.uppy-StatusBar-actionBtn--cancel {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important; }

.uppy-StatusBar-details {
  line-height: 12px !important;
  width: 13px !important;
  height: 13px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  color: #D32F2F !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  position: relative !important;
  top: -1px !important;
  left: 6px !important;
  font-size: 10px !important;
  text-align: center !important;
  cursor: help !important; }
  .uppy-StatusBar-details:hover {
    cursor: help !important; }

.uppy-StatusBar-details:after {
  line-height: 1.3 !important;
  word-wrap: break-word !important; }

.uppy-Webcam-container {
  width: 100% !important;
  height: 100% !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.uppy-Webcam-videoContainer {
  width: 100% !important;
  -ms-flex: 1 !important;
      flex: 1 !important;
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
  overflow: hidden !important;
  background-color: #000 !important; }

.uppy-Webcam-video {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important; }

.uppy-Webcam-video--mirrored {
  transform: scaleX(-1) !important; }

.uppy-Webcam-buttonContainer {
  width: 100% !important;
  height: 75px !important;
  border-top: 1px solid rgba(147, 147, 147, 0.2) !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  padding: 0 20px !important; }

.uppy-Webcam-button {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  background-color: #D32F2F !important;
  color: #fff !important;
  cursor: pointer !important;
  transition: all 0.3s !important; }

.uppy-Dashboard--wide .uppy-Webcam-button {
  width: 60px !important;
  height: 60px !important; }

.uppy-Webcam-button:hover {
  background-color: #ab2424 !important; }

.uppy-Webcam-button:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem rgba(34, 117, 215, 0.5) !important; }

.uppy-Webcam-button .UppyIcon {
  width: 30px !important;
  height: 30px !important; }

.uppy-Webcam-button--picture {
  margin-right: 12px !important; }

.uppy-Webcam-permissons {
  padding: 15px !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: center !important;
      justify-content: center !important;
  -ms-flex-flow: column wrap !important;
      flex-flow: column wrap !important;
  height: 100% !important; }

.uppy-Webcam-Title {
  font-size: 22px !important;
  line-height: 1.35 !important;
  font-weight: 400 !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
  padding: 0 15px !important;
  max-width: 500px !important;
  text-align: center !important;
  color: #000 !important; }

.uppy-Webcam-permissons p {
  text-align: center !important;
  line-height: 1.45 !important;
  color: #939393 !important;
  margin: 0 !important; }

.uppy-Webcam-permissonsIcon .UppyIcon {
  width: 100px !important;
  height: 75px !important;
  color: #b9b9b9 !important;
  margin-bottom: 30px !important; }
